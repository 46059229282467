import React, { useState, useEffect } from "react";
import { Text, Button, Flex, Stack, Input } from "@chakra-ui/react";
import { toast } from "react-hot-toast";
import { getAuth, sendSignInLinkToEmail } from "firebase/auth";

export default function Auth() {
    return (
        <Stack className="narrow" gap={12}>
            <Stack gap={12}>
                <Stack className="banner" gap={0} align="center">
                    <Text>
                        As a part of our recent acquisition, the client portal
                        is currently undergoing a transition to the new
                        provider.{" "}
                        <span className="bold">
                            If you're an existing client, you should have
                            received a letter with instructions on accessing
                            your account.
                        </span>{" "}
                        Contact us if you need any help.
                    </Text>
                </Stack>
            </Stack>
        </Stack>
    );
}
