import React, { useState, useEffect } from "react";
import {
    Box,
    AbsoluteCenter,
    Stack,
    Text,
    Link,
    Flex,
    Button,
} from "@chakra-ui/react";

export default function Home() {
    return (
        <Box position="relative" height="100vh">
            <AbsoluteCenter width="100vw" textAlign="center">
                <Stack gap={8} align="center">
                    {/* <Stack className="banner" gap={0} align="center">
                        <Text fontSize="lg">
                            We are excited to share that{" "}
                            <span className="bold">
                                humblestacker has been acquired.
                            </span>{" "}
                            More details to follow in an upcoming statement.
                        </Text>
                        <Text fontSize="lg">
                            As a part of the transition,{" "}
                            <span className="bold">
                                the humblestacker.com domain is now for sale.
                            </span>{" "}
                            For inquiries, contact us via the link below.
                        </Text>
                    </Stack> */}
                    <Text className="title">
                        Institutional <span className="orange">bitcoin</span>{" "}
                        custody and personalized lending
                    </Text>
                    <Flex>
                        <Link href="mailto:hello@humblestacker.com">
                            <Button>Get in touch</Button>
                        </Link>
                    </Flex>
                </Stack>
            </AbsoluteCenter>
        </Box>
    );
}
